import React from 'react';
import './VideoCard.css';;
const Card = ({ title, image, duration }) => {
    if (duration.startsWith("00:")) {
        duration = duration.substring(3)
    }
  return (
    <div className="card">
      <div className="card-image">
        <img src={image} alt={title} />
      </div>
      <div className="card-content">
        <span className="card-title">{title}</span>
        <span className="card-duration">{duration}</span>
      </div>
    </div>
  );
};

export default Card;
