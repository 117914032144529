import React from 'react';
import { Layout, Typography } from 'antd';
import './AppHeader.css';

const { Header } = Layout;
const { Title } = Typography;

const AppHeader = () => {
  return (
    <Header className="header">
      <div>
        <Title level={5} className="title">
          aipodcastsummary.com
        </Title>
      </div>
      <div>
        <Title level={5} className="title">
          
        </Title>
      </div>
    </Header>
  );
};

export default AppHeader;