import React, { useEffect, useState, useRef } from 'react';

const Typewriter = ({ sentences }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

  useEffect(() => {
    const type = async () => {
      const sentence = sentences[currentSentenceIndex];
      for (let i = 0; i < sentence.length; i++) {
        setCurrentText((prevText) => prevText + sentence[i]);
        await wait(25);
      }
      await wait(3000);
      for (let i = sentence.length; i >= 0; i--) {
        setCurrentText((prevText) => prevText.slice(0, -1));
        await wait(25);
      }
    //   setCurrentSentenceIndex(Math.floor(Math.random() * sentences.length));
      setCurrentSentenceIndex((prevIndex) => prevIndex + 1);
      if (currentSentenceIndex >= sentences.length-1) {
        setCurrentSentenceIndex(0)
      }
    };

    if (currentSentenceIndex < sentences.length) {
      type();
    }



  }, [currentSentenceIndex]);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return <div style={{marginTop:"20px", fontStyle:"italic", color:"grey"}}>{currentText}</div>;
};

export default Typewriter;
