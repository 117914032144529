import React, { useState } from 'react';
import { Input } from 'antd';
import VideoCard from '../components/VideoCard'
import Typewriter from '../components/Typewriter';

const { Search } = Input;

const SERVER = "https://beta.aipodcastsummary.com"
// const SERVER = "http://localhost:5000"

const Home = () => {
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [videoThumbail, setVideoThumbnail] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDuration, setVideoDuration] = useState('');
  const [videoId, setVideoID] = useState('');

  const handleClick = async () => {
    setResponseText("")
    setResponseData([])
    if (inputText == "") {
      return;
    }
    console.log(extractYouTubeVideoId(inputText))
    try {
        setIsLoading(true);
  
        const response = await fetch(SERVER + '/api/youtube-metadata/' + extractYouTubeVideoId(inputText), {
          method: 'GET',
          mode: 'cors'
        });
  
        const data = await response.json();
        setVideoThumbnail(data["thumbnail_url"]);
        setVideoTitle(data["title"]);
        setVideoDuration(data["video_length"]);
        setVideoID(extractYouTubeVideoId(inputText))
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    
    try {
      setIsLoading(true);

      const response = await fetch(SERVER + '/api/youtube/' + extractYouTubeVideoId(inputText), {
        method: 'GET',
        mode: 'cors'
      });

      const data = await response.json();
      console.log(data)
      setResponseText(JSON.stringify(data["data"]));
      setResponseData(data["data"] || []);
    } catch (error) {
      console.error('Error:', error);
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  };


  const extractYouTubeVideoId = (input) => {
    let videoId = input.trim();
  
    if (videoId.includes('youtube.com') || videoId.includes('youtu.be')) {
      const url = new URL(videoId);
      console.log(url)
      console.log(url.pathname)
      if (url.hostname === 'youtu.be') {
        videoId = url.pathname.substring(1);
      } else if (url.pathname.includes('/watch')) {
        videoId = url.searchParams.get('v');
      } else if (url.pathname.includes('/live')) {
        videoId = url.pathname.split('/').slice(-1)[0];
      }
    }
  
    return videoId;
  };

  let sentences = [
    "Just getting started, stay tuned for some amazing insights!",
    "Progress update: Unveiling valuable information from the text.",
    "Working diligently to extract the most important details.",
    "Hold on tight, there's a treasure trove of knowledge coming your way!",
    "Processing the text, uncovering hidden gems.",
    "Making steady progress towards the summary you've been waiting for.",
    "Discovering fascinating points that will make this summary exceptional.",
    "Stay patient, it's going to be worth the wait!",
    "Deep diving into the text, gathering key takeaways.",
    "Finding nuggets of wisdom within the vast amount of information.",
    "Building an insightful summary piece by piece.",
    "Unveiling hidden insights that will make this summary extraordinary.",
    "Stay optimistic, the summary is shaping up to be remarkable.",
    "Exploring the text's depths, capturing valuable insights.",
    "Every sentence analyzed brings us closer to an exceptional summary.",
    "Uncovering the essence of the text, piece by piece.",
    "Putting together a summary that will exceed your expectations.",
    "Stay tuned, we're extracting gold from the text!",
    "Just a little longer, and you'll have an incredible summary in your hands.",
    "The anticipation will pay off, as this summary will be packed with gems!"
  ];

  const getTimeInSeconds = (timeString) => {
    const timeParts = timeString.split(':');
    let seconds = 0;

    if (timeParts.length === 3) {
      const [hours, minutes, secs] = timeParts;
      seconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(secs);
    } else if (timeParts.length === 2) {
      const [minutes, secs] = timeParts;
      seconds = parseInt(minutes) * 60 + parseInt(secs);
    }

    return seconds;
  };

  const MyListItem = ({ item, id }) => {
    let timestampLink = <span></span>
    if (item.timestamp) {
      let timestampURL = "https://youtube.com/watch?v=" + videoId + "&t=" + getTimeInSeconds(item.timestamp)
       timestampLink =  <span> - (<a href={timestampURL} target="_blank" rel="noreferrer">{item.timestamp}</a>)</span>
    }
    
    return (
      <div>
    <h3 id={id}>{item.heading}{timestampLink}</h3>
    <p>{item.text}</p>
    </div>
    );
  };
  

  const contents = responseData.map((summary, index) => (
    <p><a href={"#" + index + videoId}>
      {summary.heading} {summary.timestamp ? (<span> - ({summary.timestamp})</span>) : ""}</a></p>
  ));



  const summaries = responseData.map((summary, index) => (
      <MyListItem key={index} item={summary} id={index + videoId}/>
  ));

  
  return (
    <div>
        <h3>Free AI Podcast Summary 🤖🎙️</h3>
        {videoThumbail ? <VideoCard
        image={videoThumbail}
        title={videoTitle}
        duration={videoDuration}
      /> : ""}
      <Search
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        enterButton={isLoading ? "Summarizing" : "Summarize"}
        disabled={isLoading ? true : false}
        placeholder="YouTube URL"
        size="large"
        onSearch={handleClick}
        loading={isLoading ? true : false}
      />
      {isLoading ? <Typewriter sentences={sentences} /> : ""}
      <br />
      <div style={{minHeight: "70vh"}}>
        {responseData.length > 0 ? <h3>Topics: </h3> : ""}
        {contents}
        <br />
      {summaries}
        </div>
    </div>
  );
};
/*
Loading texts:
You've got great taste in youtube videos by the way 👀
Has anyone ever told you that your thirst for knowledge is quite attractive?
This one's gonna be good
Bare with me one moment

*/

export default Home;
