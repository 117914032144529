import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import styled from 'styled-components';
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter';
import ReactGA from 'react-ga';

ReactGA.initialize('G-8KZSVSNKR0');

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
`;

// // Initialize Google Analytics with your tracking ID
// ReactGA.initialize('YOUR_TRACKING_ID');

// // Set the page view
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  // const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
        {/* <Navbar /> */}
        <AppHeader/>
        <MainContainer>
      <Routes>
          <Route index element={<Home/>} />
          {/* <Route path="/features" component={Features} />
          <Route path="/pricing" component={Pricing} /> */}
      </Routes>
        </MainContainer>
        <AppFooter/>
    </Router>

  );
}

export default App;
