import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ width: '100%', color: "grey"}}>
     © 2023 - Served to you by <a href="https://mukalere.com/">Matthew Mukalere</a>
    </Footer>
  )
}; 

export default AppFooter;
